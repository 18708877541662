@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-block: var(--spacing-s)  var(--spacing-m);;
  @media (--viewportMedium) {
    padding-block: var(--spacing-m) var(--spacing-l);
  }
}

.form {
  flex-grow: 1;
}

.title {
  @apply --marketplaceH3FontStyles;
}
  
/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* This is the title for the Edit Photos component */
.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.seePhotoTips {
  color: #000;
  text-decoration: underline;
}