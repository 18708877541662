@import '../../marketplace.css';

:root {
}

.root {
  @apply --marketplaceContainerStyles;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  margin-block: var(--spacing-m);

  text-align: center;
  gap: var(--spacing-s);

  @media (--viewportMedium) {
    flex-flow: row wrap;
    justify-content: center;
    margin-block: var(--spacing-l);
    gap: var(--spacing-m);
  }
}

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0;
  font-size: 24px;
  flex-shrink: 0;
  width: 100%;
  @media (--viewportMedium) {
    font-size: 36px;
  }
}
